import React from 'react';
import './App.css';
import { IndexPage, AppsPage, LoginPage, RestorePwdPage, ResetPwdPage, InviteRegisterPage, RegisterPage } from './pages';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { StoreProvider } from './stores';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#c71a1a',
    },
    primary: {
      main: '#fff',
    },
  }
})

const App: React.FC = (props) => {
  console.log("app-root: ", process.env.REACT_APP_BUILD_VSN);
  console.log("commit_id: ", process.env.REACT_APP_BUILD_COMMIT_ID);
  console.log("build_date: ", process.env.REACT_APP_BUILD_DATE_TIME);
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <IndexPage></IndexPage>
          <HashRouter>
            <Switch>
              <Route path="/token/:token" component={LoginPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/restorePwd" component={RestorePwdPage} />
              <Route path="/resetPwd" component={ResetPwdPage} />
              <Route path="/acceptInvite" component={InviteRegisterPage} />
              <Route path="/registerSubmit" component={InviteRegisterPage} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/defaultapp" component={AppsPage} />
              <Route path="/" component={AppsPage} />
              <Redirect to="/login" />
            </Switch>
          </HashRouter>
        </React.Fragment>
      </ThemeProvider>
    </StoreProvider>
  );
};

export function parseHashLocation(locationHash: string) {
  if (locationHash[0] === '?') {
    locationHash = locationHash.slice(1);
  };
  //
  const props = locationHash.split("&");
  let lState: any = {};
  //
  for (let i = 0; i < props.length; i++) {
    const parts = props[i].split("=");
    if (parts.length < 2) {
      parts.push("");
    }
    if (parts[0] === '') {
      continue;
    };
    lState[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
  }
  return lState;
};

export default App;
