
// ---
export async function getEraDefaultsJson() {
    const reqOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    };
    const resp: Response = await fetch('/rest/v1/public/resources/defaults.json', reqOptions);
    let respBody: any = {};
    let result = [];
    try {
        respBody = await resp.json();
    } catch { };
    //
    if (resp && resp.status >= 200 && resp.status <= 299) {
        return respBody;
    };
};

// ---
export async function getRegisterDomains() {
    const reqOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    };
    const resp: Response = await fetch('/rest/v1/public/resources/defaults.json', reqOptions);
    let respBody: any = {};
    let result = [];
    try {
        respBody = await resp.json();
    } catch { };
    //
    if (resp && resp.status >= 200 && resp.status <= 299) {
        const regDomains = respBody.selfRegisterDomains;
        if (regDomains) {
            result = regDomains
        }
    };
    return result
};

// ---
export function getSuccessTextByPage(pageName: string, defaultText: string) {
    if (successResultTexts.hasOwnProperty(pageName)) {
        return successResultTexts[pageName]
    } else {
        return defaultText
    }
};

// ---
const successResultTexts: any = {
    restorePwd: 'Ссылка для сброса пароля отправлена на email',
    register: 'Ссылка для подтверждения регистрации отправлена на email',
    registerSubmit: 'Используйте новый пароль для авторизации',
    inviteSubmit: 'Используйте новый пароль для авторизации',
    resetPwd: 'Используйте новый пароль для авторизации'
};

// ---
export function getErrorTextByCode(errorDetails: any, defaultText: string) {
    if (errorCodesTexts.hasOwnProperty(errorDetails.msg_code)) {
        if (errorDetails.hasOwnProperty('field')) {
            const resultText = errorCodesTexts[errorDetails.msg_code][errorDetails.field];
            return resultText ? resultText : defaultText;
        } else {
            const codeText = errorCodesTexts[errorDetails.msg_code];
            if (typeof codeText === 'string' || codeText instanceof String) {
                return codeText
            } else {
                return defaultText
            }
        }
    } else {
        return defaultText
    }
}

// ---
const errorCodesTexts: any = {
    10001: 'Домен не найден',
    10002: 'Пользователь не найден',
    10006: {
        'opts.email': 'Некорректный email',
        // 'pwd': 'Некорректный пароль'
    },
    10012: 'Доступ запрещен',
    15304: 'Email не найден. Обратитесь к администратору для смены пароля или установки email.'
};