import React, { useState, useEffect } from 'react';
import { Theme, Card, CardContent, TextField, Button, CircularProgress, Link } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import useReactRouter from 'use-react-router';
import './LoginPage.css';
import { useStore } from '../../stores';
import * as DataUtils from '../../stores/DataUtils';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { parseHashLocation } from '../../App'
import { ClassNameMap } from '@material-ui/styles/withStyles';

// -----------
// Defines
// -----------

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#EAEAEA',
            overflow: 'auto',
            // border: '1px solid black',
        },
        container2: {
            flexBasis: 'content',
            justifyContent: 'center',
            height: '100%',
            width: '90%',
            maxWidth: '400px',
            // border: '1px solid black',
        },
        card: {
            padding: '0 8px',
        },
        logo: {
            flex: '1 1 20%',
            paddingTop: 24,
            paddingLeft: 24,
            // border: '1px solid black',
        },
        logoImg: {
            padding: '0 0 0 0',
            margin: '0 0 0 0',
            maxHeight: 100,
            maxWidth: 200,
            // border: '1px solid black',
        },
        header: {
            marginTop: 16,
            paddingLeft: 24,
            paddingRight: 24,
            fontSize: '24px',
            // border: '1px solid green',            
        },
        div: {
            // border: '1px solid red',
        },
        form: {
            padding: '0 0 0 0',
            // border: '1px solid black',
        },
        inner: {
            width: '200',
            display: 'flex',
            flexDirection: 'column'
            // border: '1px solid black',
        },
        textField: {
            marginLeft: 0,//theme.spacing(1),
            marginRight: 0,//theme.spacing(1),
            width: '100%',
        },
        linkContainer: {
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '20px'
        },
        linkField1: {
            flex: '1',
            marginBottom: '1.5em',
            maxWidth: '50%',
            textAlign: 'left',
            textIndent: '1.2em',
            //marginLeft: 0,//theme.spacing(1),
            //marginRight: 0,//theme.spacing(1),
            color: '#0000EE'
        },
        linkField2: {
            flex: '2',
            marginBottom: '1.5em',
            maxWidth: '50%',
            textIndent: '2.6em',
            //marginLeft: 0,//theme.spacing(1),
            //marginRight: 0,//theme.spacing(1),
            color: '#0000EE'
        },
        error: {
            fontSize: '12px',
            color: 'red',
            marginLeft: 0
        },
        divButton: {
            padding: '8 0',
            width: "100%",
            position: 'relative',
            marginBottom: '0.20em'
        },
        authButtons: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '96%',
            maxWidth: '400px',
            paddingLeft: '0.5em',
            alignItems: 'inherit',
            paddingBottom: '16px',
        },
        authButtonHref: {
            flex: '1 1 auto',
            cursor: 'pointer',
            minWidth: '64px',
            maxHeight: '36px',
            margin: '0.5em',
            textAlign: 'center',
            //
            border: '1px solid lightgray',
            borderRadius: '4px',
            //
            textDecoration: 'none',
            fontSize: '16px',
            color: 'black',
            overflow: 'hidden',
        },
        authButtonImg: {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: '1.7em',
            height: '1.65em',
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: '0.3em 0.5em 0.3em 0.2em'
        },
        button: {
            width: '100%',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -20,
        },
        buttonAuthProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -20,
            color: 'lightblue'
        }
    }),
);

type state = {
    domain: string,
    login: string,
    pwd: string,
    authToken?: string,
    processing: boolean,
    switchOn: boolean,
    errorText: string
};

type authButton = {
    url: string;
    img: string;
    label: string;
};

// -----------

const next = (history: any, sessionCurrent: any, hashLocation?: any) => {
    const cookie: string = document.cookie;
    const index: number = cookie.indexOf('next=');
    if (index >= 0) {
        const indexEnd: number = cookie.indexOf(';', index + 1);
        let nextStr: string = (indexEnd > index) ? cookie.substring(index + 5, indexEnd) : cookie.substring(index + 5);
        //const upCookie: string = cookie.replace(`next=${nextStr};`, '').replace(';', '').trim();
        document.cookie = 'next=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        window.location.assign(nextStr);
    } else {
        if (hashLocation.meetreferrer) {
            window.location.assign(hashLocation.meetreferrer);
        } else {
            let defaultWebApp: string | undefined;
            try {
                defaultWebApp = sessionCurrent.ext.default_webapp
            } catch {
                defaultWebApp = undefined
            };
            if (defaultWebApp) {
                window.location.assign("/" + defaultWebApp + "/");
            } else {
                history.push("/");
            }
        }
    }
};

const LoginPage: React.FC = (props: any) => {
    const store = useStore();
    const classes = useStyles();
    //
    const { history } = useReactRouter();
    const hashLocation = parseHashLocation(history.location.search);
    const token = props.match.params.token;
    const domain = hashLocation.domain ? hashLocation.domain : (global as any).eraDefaultDomain === 'URL' ? window.location.hostname : (global as any).eraDefaultDomain;
    const login = hashLocation.login ? hashLocation.login : "";
    //
    const defaultState: state = {
        domain: domain,
        login: login,
        pwd: '',
        authToken: token,
        switchOn: false,
        processing: false,
        errorText: ''
    };
    const [state, setState] = useState(defaultState);
    const [registerDomains, setRegisterDomains] = useState([]);
    const [authButtons, setAuthButtons] = useState([] as authButton[]);

    useEffect(() => {
        if (token) {
            loginByToken(state, setState, history);
        };
        DataUtils.getEraDefaultsJson().then((defaultsJson) => {
            const regDomains = defaultsJson.selfRegisterDomains;
            if (regDomains && registerDomains !== regDomains) {
                setRegisterDomains(regDomains)
            }
            //
            const defAuthButtons: authButton[] = defaultsJson.authButtons;
            if (defAuthButtons && authButtons !== defAuthButtons) {
                setAuthButtons(defAuthButtons)
            }
        });
    }, []);

    const logo = "/rest/v1/public/resources/logo";

    // login by submit form
    const submitHandler = (e: any) => {
        e.preventDefault();
        setState({ ...state, processing: true, errorText: "" });
        if (store) {
            store.login(state, state.switchOn).then(function (result) {
                if (result.isSuccess) {
                    store.setAuthorized(true);
                    next(history, result.data, hashLocation);
                } else {
                    const errorText = DataUtils.getErrorTextByCode(result.errorDetails, store.errorText);
                    setState({ ...state, processing: false, errorText: errorText });
                }
            });
        }
    };

    // login by token
    async function loginByToken(state: state, setState: Function, history: any) {
        if (store) {
            const sessResult = await postTokenSession();
            const resp = sessResult.resp;
            const respBody = sessResult.respBody;
            //
            if (resp && resp.status >= 200 && resp.status <= 299) {
                const sessCurrent = await getSessionCurrent();
                store.setAuthorized(true);
                next(history, sessCurrent);
                history.push('/');
            } else {
                setState({ ...state, errorText: respBody.error_message, authToken: undefined })
            }
        };
    };

    async function postTokenSession() {
        let body: any = {
            token: state.authToken
        };
        const reqOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(body)
        };
        const resp: Response = await fetch('/rest/v1/iam/sessions', reqOptions);
        //
        let respBody: any = {};
        try {
            respBody = await resp.json();
        } catch {
            respBody['error_message'] = resp.statusText;
        };
        return { resp: resp, respBody: respBody }
    };

    async function getSessionCurrent() {
        const reqOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        };
        const resp: Response = await fetch('/rest/v1/iam/sessions/current', reqOptions);
        let respBody: any = {};
        try {
            respBody = await resp.json();
        } catch { };
        return respBody;
    };

    // ---
    const toggleChecked = () => {
        const changeSwitch = !state.switchOn;
        setState({ ...state, switchOn: changeSwitch });
    };

    const defaultPage = (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.container2}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><img src={logo} alt="l" className={classes.logoImg} /></div>
                        <div className={classes.header}>Авторизация</div>
                        <div className={classes.div}>
                            <form className={classes.form} onSubmit={submitHandler}>
                                <CardContent className={classes.inner} >
                                    <TextField
                                        required
                                        id="domain"
                                        label="Домен"
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={e => setState({ ...state, domain: e.target.value, errorText: "" })}
                                        value={state.domain}
                                    />
                                    <TextField
                                        required
                                        id="login"
                                        label="Логин"
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={e => setState({ ...state, login: e.target.value, errorText: "" })}
                                        value={state.login}
                                    />
                                    <TextField
                                        required
                                        id="pwd"
                                        label="Пароль"
                                        className={classes.textField}
                                        type="password"
                                        margin="normal"
                                        onChange={e => setState({ ...state, pwd: e.target.value, errorText: "" })}
                                    />
                                    <div style={{ padding: '9px 0' }}>
                                        <p style={{ margin: '0' }} className={classes.error}>{state.errorText}</p>
                                    </div>
                                    <div className={classes.divButton}>
                                        <Button variant="contained" disabled={state.processing} className={classes.button} type="submit">Войти</Button>
                                        {state.processing && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                    {state.errorText.includes("1410") &&
                                        <div style={{ height: 24 }}>
                                            {<FormControlLabel className={classes.textField} control={<Switch color="primary" checked={state.switchOn} onChange={toggleChecked} />} label="предварительно закрыв все сессии" />}
                                        </div>
                                    }
                                </CardContent>
                            </form>
                        </div>
                        <div className={classes.linkContainer}>
                            <Link
                                component="button"
                                variant="inherit"
                                underline='none'
                                className={classes.linkField1}
                                onClick={() => {
                                    const hashParams = getMeetReferrerHashParam(hashLocation);
                                    history.push("/restorePwd" + hashParams);
                                }}
                            >
                                Восстановить пароль
                            </Link>
                            <Link
                                component="button"
                                variant="inherit"
                                underline='none'
                                className={classes.linkField2}
                                hidden={registerDomains.length === 0}
                                onClick={() => {
                                    const hashParams = getMeetReferrerHashParam(hashLocation);
                                    history.push("/register" + hashParams);
                                }}
                            >
                                Зарегистрироваться
                            </Link>
                        </div>
                        {renderAuthButtons(authButtons, history, classes)}
                    </Card>
                </div>
            </div>
        </React.Fragment>);

    const tokenPage = (
        <React.Fragment>
            <div>
                <CircularProgress size={96} className={classes.buttonAuthProgress} />
            </div>
        </React.Fragment>
    );
    //
    let page = defaultPage;
    if (state.authToken) {
        page = tokenPage;
    };
    return page;
};

// ---
function renderAuthButtons(authButtons: authButton[], history: any, classes: ClassNameMap) {
    const result = authButtons.map((authButton) => {
        let url = authButton.url;
        if (!authButton.url.match('^https?:\/\/')) {
            if (authButton.url.startsWith('/')) {
                url = window.location.protocol + '//' + window.location.host + url;
            } else {
                url = window.location.protocol + '//' + window.location.host + window.location.pathname + url;
            }
        };
        const icon =
            <a key={url + '__' + authButton.img} href={url} className={classes.authButtonHref} >
                <div className={classes.authButtonImg} style={{ backgroundImage: 'url(' + authButton.img + ')' }} />
                <span style={{ verticalAlign: 'middle' }}>{authButton.label}</span>
            </a>
        return icon;
    });
    return <div className={classes.authButtons}>
        {result}
    </div>;
};

// ---
function getMeetReferrerHashParam(hashLocation: any) {
    let hashParam = '';
    if (hashLocation.meetreferrer) {
        hashParam = '?meetreferrer=' + hashLocation.meetreferrer;
    };
    return hashParam
};


export default LoginPage;