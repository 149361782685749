import AppModel from './AppModel';
import { types, Instance, flow, getParent } from 'mobx-state-tree';

const AppItems = types.array(AppModel);

export type IAppItems = Instance<typeof AppItems>;
export type fillResult = {
    isSuccess: boolean,
    data?: any
};

const AppStore = types.model({
    items: AppItems,
}).views(self => ({
    getApps: () => {
        return self.items;
    }
})).actions(self => ({
    fill: flow(function* fill() {
        const store: any = getParent(self);
        let result: fillResult = { isSuccess: false };

        const resp: any = yield fetch('/rest/v1/iam/sessions/current');

        if (!resp) {
            store.setAuthorized(false);
            self.items.clear();
            return result;
        }

        if (resp.status >= 300) {
            store.setAuthorized(false);
            self.items.clear();
            return result;
        }

        const body: any = yield resp.json();
        if (body['webapps'] === undefined) {
            store.setAuthorized(false);
            self.items.clear();
            return result;
        }

        store.setAuthorized(true);
        const apps = body['webapps'].map((item: { name: string, description: string, url: string, order: number, 'fa-icon': string, icon?: string }) => ({
            id: item.name + '_' + item.url,
            title: item.name ? item.name : "",
            hint: item.description ? item.description : "",
            imgUrl: item['icon'] ? item['icon'] : item['fa-icon'] ? item['fa-icon'] : '',
            isIconFA: item['icon'] ? false : item['fa-icon'] ? true : false,
            appUrl: item.url ? item.url : "",
            order: item.order ? item.order : 10000,
            faIcon: item['fa-icon'] ? item['fa-icon'] : "fa-window-close"
        }));
        self.items = apps.sort(function (A: { order: number }, B: { order: number }) { return A.order - B.order });

        store.setSessionInfo(body);

        result.isSuccess = true;
        result.data = body;

        return result;
    })
}));

export default AppStore;